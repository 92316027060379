<template>
  <div class="common-container msg-detail-container">
    <div class="common-content">
      <div class="flex msg-detail-top">
        <div class="flex-center detail-top-box">
          <div class="top-back" @click="goBack">
            <i
              class="el-icon-arrow-left
"
            ></i>
            返回
          </div>
          消息详情
        </div>
        <!-- <div class="flex-center msg-gz-box" v-if="status == 2">
          已关注
        </div> -->
      </div>

      <div class="scroll-Y msg-detail-box">
        <div class="msg-form">
          <div class="flex msg-form-item">
            <div class=" msg-form-label">问题内容：</div>
            <div class="msg-form-content">
              <el-input
                v-model="msgData.content"
                placeholder="请输入内容"
                type="textarea"
                class="textarea-info"
                :rows="3"
                readonly
                resize="none"
              ></el-input>
            </div>
          </div>
          <div
            class="flex msg-form-item"
            style="align-items:center;"
            v-if="fileList.length > 0"
          >
            <div class=" msg-form-label">问题附件：</div>
            <div class="flex msg-form-content">
              <div class="flex-center file-box">
                <img
                  :src="$returnFileImg(fileList[0].name)"
                  class="file-img"
                  alt=""
                />
                {{ fileList[0].name }}
              </div>
              <span class="msg-color" @click="downLoad" style="margin-left:8px;"
                >下载</span
              >
            </div>
          </div>
          <div class="flex msg-form-item">
            <div class=" msg-form-label">历史记录：</div>
            <div
              class="msg-form-content scroll-Y msg-history-box"
              ref="mianscroll"
            >
              <template v-if="list.length > 0">
                <div
                  class="flex msg-history-item"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <div class="history-user">
                    {{ $formatDate(item.sendTime, "yyyy-MM-dd hh:mm:ss") }}
                  </div>
                  <div class="flex-center history-circle">
                    <div class="flex-center circle-box">
                      <div class="circle"></div>
                    </div>
                  </div>
                  <div class="history-content">
                    <div class="history-name">
                      {{ item.sender && item.sender.userName }}
                    </div>
                    <div class="history-text">
                      {{ item.content }}
                    </div>
                    <div class="file-box" v-if="item.file">
                      <img
                        src="~images/home/icon_file.png"
                        class="file-img"
                        alt=""
                      />
                      附件名称.zip
                    </div>
                  </div>
                </div>
              </template>
              <div class="noData" v-else>暂无聊天记录</div>
            </div>
          </div>
          <div class="flex msg-form-item">
            <div class=" msg-form-label">回复内容：</div>
            <div class="msg-form-content">
              <el-input
                v-model="content"
                placeholder="请输入回复内容"
                type="textarea"
                :rows="4"
                resize="none"
                :disabled="status !== 1"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="flex msg-btn">
          <!-- <el-button
            v-if="status !== 2"
            size="medium"
            @click="viewIt"
            type="primary"
            >关注</el-button
          > -->
          <template v-if="status == 1">
            <el-button size="medium" @click.stop="sureBack">确认回复</el-button>
            <el-button type="primary" size="medium" @click="sureConfirm"
              >完成工单</el-button
            >
          </template>
          <!-- <el-button v-else size="medium" @click="$router.go(-1)"
            >返回</el-button
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, postData, post } from "@/api/index";
import { webSocket } from "@/utils/webSocket";
import { getToken } from "@/utils/auth";
import axios from "axios";
export default {
  name: "msgDetail",
  components: {},
  mixins: [webSocket],
  data() {
    return {
      status: 0,
      msgData: {
        content: ""
      },
      list: [],
      content: "",
      id: "",
      fileList: [],
      recipientUserId: "",
      feedbackId: "",
      isMsgDetail: true
    };
  },
  created() {},
  computed: {},
  activated() {
    this.id = this.$route.query.id;
    this.isMsgDetail = true;
    if (this.$route.query.id) {
      this.getDetail();
    } else this.$router.go(-1);
  },
  mounted() {
    this.$nextTick(() => {
      this.openServer();
    });
  },
  methods: {
    getDetail() {
      get(this.$setApi("/cus/feedback/") + this.id).then(res => {
        this.getHistoryData(this.id);
        if (res.data) {
          this.fileList = [];
          this.status = res.data.status;
          this.msgData.content = res.data.content || "";
          this.recipientUserId = res.data.replyUserId || "1";
          this.feedbackId = res.data.id || "";

          if (res.data.files && res.data.files.length > 0) {
            res.data.files.map(item => {
              this.fileList.push({
                id: item.id,
                name: item.fileName,
                url: item.url
              });
            });
          }
          console.log("files", this.fileList, res.data.files);
        }
      });
    },
    getHistoryData(id) {
      this.list = [];
      post(this.$setApi("/cus/ws/chat/history/" + id)).then(res => {
        this.list = res.data || [];
        this.markMessageAsRead({
          recipientUserId: this.recipientUserId,
          feedbackId: id
        });
        // this.handleScrollBottom();
      });
    },
    downLoad() {
      if (this.fileList && this.fileList.length > 0 && this.fileList[0].id) {
        axios({
          url:
            window.configData.VUE_APP_BASEURL + "/file/download/id/" +
            this.fileList[0].id, // 请求地址
          method: "get",
          headers: {
            Authorization: "Bearer " + getToken()
          },
          responseType: "blob" // 表明返回服务器返回的数据类型
        })
          .then(res => {
            if (res.data) {
              // let url = window.URL.createObjectURL(res.data);
              // let link = document.createElement("a");
              // link.style.display = "none";
              // link.href = url;
              // link.setAttribute("download", this.fileList[0].fileName);
              // document.body.appendChild(link);
              // link.click();
              let url = window.URL.createObjectURL(res.data);
              let link = document.createElement("a");
              link.style.display = "none";
              link.href = url;
              // link.setAttribute("download", this.fileList[0].fileName);
              let contentDisposition = res.headers["content-disposition"];
              let filename = contentDisposition
                .split(";")[1]
                .trim()
                .split("=")[1]
                .replace(/"/g, "");
              link.setAttribute("download", encodeURIComponent(filename)); //避免中文名的问题，服务端对文件名编码过，需要进行解码获
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(err => {
            console.log("err", err);
          });
      }
    },
    viewIt() {
      let data = {
        id: this.id
      };
      if (this.status !== 2) data.status = 2;
      postData(this.$setApi("/cus/feedback/update"), data).then(res => {
        if (res.code == 200) {
          this.$message.success("消息已关注");
          this.getDetail();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    sureBack() {
      if (!this.content) {
        this.$message.warning("不能发送空消息!!!");
        return;
      }
      let data = {
        recipientUserId: this.recipientUserId,
        feedbackId: this.feedbackId,
        content: this.content
      };
      this.sendMessage(data);
    },
    pusMsg(data) {
      this.list.shift(data);
      this.content = "";
      this.getHistoryData(this.id);
    },
    sureConfirm() {
      this.$confirm("确定完成工单?", "温馨提示", {
        confirmButtonText定: "确定",
        cancelButtonText: "取消",
        type: "success"
      })
        .then(() => {
          let data = {
            id: this.id,
            status: 0
          };
          postData(this.$setApi("/cus/feedback/update"), data).then(res => {
            if (res) {
              this.$message({
                message: "工单已成功",
                type: "success"
              });
              this.getDetail();
            }
          });
        })
        .catch(() => {});
    }
  },
  watch: {}
};
</script>
<style lang="stylus" scoped>
.msg-detail-container
    .common-content
      overflow hidden
      margin 24px auto
      height calc(100% - 48px)
      background: #fff
      border-radius: 8px
    .msg-detail-top
      position: relative
      height: 72px
      padding 0 24px
      justify-content: space-between
      background: #FFFFFF
      border-radius: 8px 8px 0px 0px
      border: 1px solid #EEEEEE
      font-size 16px
      color #222
      font-weight 400
    .detail-top-box
      height 100%
      justify-content: flex-start
    .msg-gz-box
      position absolute
      top -1px
      right -1px
      background #1777FF
      font-size 12px
      padding 8px 12px
      color #fff
      border-radius 0px 12px 0px 12px
      cursor pointer
    .top-back
      margin-right 24px
      color #3478F7
      cursor pointer
.msg-detail-box
    padding: 0px 96px 32px 96px
    height calc(100% - 70px)
    .file-box
        padding 0 8px
        height: 32px
        border-radius: 2px
        border: 1px solid #F0EEEE
        background: #fff
    .file-img
        width 18px
        margin-right 4px
        vertical-align: middle
    .msg-btn
        justify-content: flex-end
        margin-top: 56px
    .msg-form
        width 100%
        .msg-form-item
            width 100%
            margin-top: 32px
            align-items: flex-start
        .msg-form-label
            width 70px
        .msg-form-content
            width: calc(100% - 70px)
            align-items: center
            >>>.el-input
                widht 100%
            >>>.el-textarea__inner
                padding 0 15px
            .textarea-info >>>.el-textarea__inner
                border none
    .msg-color
        color #3478F7
        font-size 14px
        font-weight: 400
        cursor pointer
.msg-history-box
    overflow-y: auto
    height: 300px
    min-height 100px
    padding 8px 12px
    font-size 14px
    font-weight 400
    color #333
    border-radius: 4px
    .msg-history-item
        width 100%
        margin-bottom 24px
        align-items: flex-start
        justify-content: flex-start
        &:last-child
          .history-content
            &:before
              display: none
    .history-circle
        position: relative
        width 24px
        height: 24px
        align-items: flex-start
        z-index 1
        .circle-box
          width 12px
          height 12px
          border-radius: 50%
          background: #d4e5ff
          .circle
            width 6px
            height 6px
            border-radius: 50%
            background: #1472fe
    .history-content
        position: relative
        flex 1
        &:before
          position: absolute
          top 6px
          left -12px
          content ""
          display: block
          width 1px
          height calc(100% + 24px)
          background: #eee
          z-index 0
        .file-box
          margin-top 4px
          display: inline-block
          line-height: 32px
    .history-name
        height 20px
        font-weight 500
    .history-text
        padding 4px 0
        color #999
        line-height: 20px
        box-sizing: border-box
    .history-user
        font-size 14px
        color #999
        font-weight 400
</style>
